import '../../assets/styles/pages.scss'
import { Link, useLocation, useMatch, useNavigate } from 'react-router-dom'
import DownloadFileModal from '../../components/common/download-file-modal'
import { useContext, useEffect, useState } from 'react'
import henceforthApi from '../../utils/henceforthApi'
import { DeliveryList, sellerlist } from '../../context/interfaces'
import PaginationLayout from '../../components/PaginationLayout'
import { GlobalContext, handleError } from '../../context/Provider'
import profile_placeholder from '../../assets/images/pages/profile_placeholder.png'
import moment from 'moment'
import BreadCrumb from '../../components/common/BreadCrumb'
import Spinner from '../../components/BootstrapCompo'
import { INSUFFICIENT_PERMISSIONS } from '../../context/actionTypes'
import NODATA from '../../assets/images/no-data-found.svg'
import { Tooltip } from 'antd'

const ReportsListing = () => {

    let breadCrumbPath = [
        { name: 'Home', url: `/`, active: '' },
        { name: 'Report list', url: ``, active: 'not-allowed' }
    ]

    const match = useMatch("reports/:page")
    const location = useLocation()
    const newParam = new URLSearchParams(location.search);
    const { authState, onChangePagination } = useContext(GlobalContext);
    let limit = 10
    const [totalcount, setTotalCount] = useState(0)    
    const [search, setSearchData] = useState("")
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [state, setstate] = useState<DeliveryList>()

    const initialise = async () => {
        try {
            setLoading(true)
            let q = newParam.get("filter")
            let searchData = newParam.has("search") ? newParam.get("search") : null;
            let Apires = await henceforthApi.Delivery.getReportsListing(
                searchData,
                q,
                Number(match?.params?.page) - 1,
                limit
            )
            setstate(Apires)
            setTotalCount(Apires?.data.count);
        } catch (error: any) {
            if (error?.response?.body?.error === INSUFFICIENT_PERMISSIONS) {
                window.history.back()
            }
        } finally {
            setLoading(false)
        }
    }
    const onSearch = (searchall: any) => {
        if (searchall) {
            navigate(`/reports/1?search=${searchall}`)
        }
        else {
            navigate(`/reports/1`)
        }
    }

    const onChangeFilter = (type: any) => {
        const newParam = new URLSearchParams()
        if (type) {
            newParam.set("filter", type)
        }
        navigate({ search: newParam.toString() })
    }


    useEffect(() => {
        initialise()
    }, [match?.params?.page, newParam.get("search"), newParam.get("filter"), authState?.lang])

    return (
        <>
            {/* breadcrum  */}
            <BreadCrumb pathNameDeclare={breadCrumbPath} />

            {/* page  */}
            <div className='page-spacing'>
                <section className='product-listing'>
                    <div className="container-fluid px-xs-0">
                        {/* table  */}
                        {loading ? <div className='d-flex justifly-content-center'><Spinner /></div> :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="common-card">
                                        <div className="common-card-title">
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <h5>Report Listing</h5>
                                            </div>
                                        </div>
                                        <div className="common-card-content">
                                            {/* table */}
                                            <div className='data-list-table table-responsive mb-3'>
                                                <table className="table table-striped align-middle">
                                                    <thead className=''>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>Initilize Report User Name</th>
                                                            <th>Reported User</th>
                                                            <th>Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(state?.data?.data) && state?.data?.data.length ? state?.data?.data.map((res: any, index: any) =>
                                                            <tr key={res.id}>
                                                                <td>{Number(match?.params?.page) == 0 ? index + 1 : (Number(match?.params?.page) - 1) * limit + (index + 1)}</td>
                                                                <td className='product-image-table'>
                                                                    <Link to={`/user/${res?.user_id?._id}`}>
                                                                        <img src={res?.user_id?.profile_pic ? henceforthApi.FILES.imageSmall(res?.user_id?.profile_pic) : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res?.user_id?.name ? `${res?.user_id?.name}` : "Not Available"}
                                                                    </Link>
                                                                </td>
                                                                <td className='product-image-table'>
                                                                    <Link to={`/user/${res?.reported_user?._id}`}>
                                                                        <img src={res?.reported_user?.profile_pic ? henceforthApi.FILES.imageSmall(res?.reported_user?.profile_pic) : profile_placeholder} alt="img" className='rounded-circle me-2' /> {res?.reported_user?.name ? `${res?.reported_user?.name}` : "Not Available"}
                                                                    </Link>
                                                                </td>
                                                                <td ><Tooltip title={res.reason && res.reason}>{res.reason ? `${res.reason.length > 50 ? res.reason.slice(0, 50) + '...' : res.reason }` : "Not Available"}</Tooltip></td>
                                                            </tr>
                                                        ) : <tr><td className='text-center py-3' colSpan={7}><img src={NODATA} width="100" /><p className='text-center mt-3'>No data found</p></td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                            {/* pagination  */}
                                            <div className='dashboad-pagination-box'>
                                                <PaginationLayout
                                                    count={totalcount}
                                                    data={state?.data?.data}
                                                    page={Number(match?.params?.page)}
                                                    limit={Number(limit)}
                                                    onPageChange={(val: any) => onChangePagination(val)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                    </div>
                </section>
            </div>
        </>
    )
}
export default ReportsListing;